#info::-webkit-scrollbar {
  width: 8px;
}

#info::-webkit-scrollbar-thumb {
  background-color: rgb(227, 134, 156);
  border-radius: 4px;
}

#info::-webkit-scrollbar-track {
  background-color: #ece6fc;
}

#info::-webkit-scrollbar-corner {
  background-color: rgb(247, 184, 199);
}

.chat-input::-webkit-scrollbar {
  width: 12px;
}

.chat-input::-webkit-scrollbar-thumb {
  background-color: rgb(247, 184, 199);
  border-radius: 12px;
}

.chat-input::-webkit-scrollbar-track {
  background-color: #ece6fc;
}

.chat-input::-webkit-scrollbar-corner {
  background-color: transparent;
}
