@keyframes subtleGlow {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.glow-effect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  animation: subtleGlow 2s ease-in-out;
  pointer-events: none;
}

@media (max-width: 768px) {
  @keyframes subtleGlow {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
}
